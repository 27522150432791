.navbar-toggler-icon-blue {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 131, 194, 0.5)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.navbar-brand-border-right {
    border-right: 1px solid #2780E3;
}
.navbar-brand{
    margin-right: 0em !important;
}

.dropdown-menu.dropdown-menu-right.show{
    border-radius: 0;
    padding: 0px;
    margin: 0px;
    text-transform: uppercase;
}

span.dropdown-item.cursorPointer{
    padding: 10px;
}
span.dropdown-item.cursorPointer:hover{
    background: #cf5232;
    color: #FFFFFF;
}

.fontSize {
    /* font-size: 18px; */
    font-family: 'Oswald';
    font-size: 1.3rem;
}

.imageMargin {
    margin-top: -10px;
    margin-bottom: -11px;
    margin-left: 30%;
}

.dropdown-toggle::after {
    content: none;
}

.cursorPointer {
    cursor: pointer;
}

.d-inline-block.align-top.unirac-logo{
    width: 159px;
    height: 34px;
}

.sso-navbar{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
}
.user-profile{
    display: flex;
    align-items: center;
    color: #0a3c5d;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
}

.nav-link.avtar{
    margin-left: 10px;
}

.nav-link.dropdown{
    position: relative;
    right: 10px;
}