@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'PT Sans';
  src: url(/static/media/PTSans-Regular.5b127e9e.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url(/static/media/Oswald-Regular.68a85f5c.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

.btn.btn-block.submitButtonColor{
  color: #fff !important; 
  background: #2284C3 0% 0% no-repeat padding-box !important;
  box-shadow: 7px 6px 4px #00000029;
  border-radius: 5px;
  opacity: 1;
  font-weight: 700;
  padding: 10px;
  margin-bottom: 44px;
}

.width100 {
  width: 100%;
}

.App.login{
  background: url(/static/media/login_background.4cc8258c.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 70%;
  height: calc(100vh);
  margin: 0;
  position: relative;
}

.App.registration{
  background: url(/static/media/register_background.20780cef.png);
  background-repeat: no-repeat;
  background-position: 44% 77%;
  background-origin: padding-box;
  margin: 0;
  height: calc(100vh);
  position: relative;
}

.App.dashboard{
  background: transparent url(/static/media/dashboard_background.7903398f.png);
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: 110%;
  background-origin: padding-box;
  opacity: 0.95;
  height: calc(99vh);
  margin: 0;
  position: relative;
}

.spinner {
    position: absolute;
    left: 48%;
    top: 40%;
    height: 60px;
    width: 60px;
    margin: 0px auto;
    -webkit-animation: rotation .6s infinite linear;
    animation: rotation .6s infinite linear;
    border-left: 6px solid rgba(0, 174, 239, .15);
    border-right: 6px solid rgba(0, 174, 239, .15);
    border-bottom: 6px solid rgba(0, 174, 239, .15);
    border-top: 6px solid rgba(0, 174, 239, .8);
    border-radius: 100%;
    z-index: 2;
}

.overlay {
    position: fixed;
    z-index: 1051;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
.navbar-toggler-icon-blue {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 131, 194, 0.5)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.navbar-brand-border-right {
    border-right: 1px solid #2780E3;
}
.navbar-brand{
    margin-right: 0em !important;
}

.dropdown-menu.dropdown-menu-right.show{
    border-radius: 0;
    padding: 0px;
    margin: 0px;
    text-transform: uppercase;
}

span.dropdown-item.cursorPointer{
    padding: 10px;
}
span.dropdown-item.cursorPointer:hover{
    background: #cf5232;
    color: #FFFFFF;
}

.fontSize {
    /* font-size: 18px; */
    font-family: 'Oswald';
    font-size: 1.3rem;
}

.imageMargin {
    margin-top: -10px;
    margin-bottom: -11px;
    margin-left: 30%;
}

.dropdown-toggle::after {
    content: none;
}

.cursorPointer {
    cursor: pointer;
}

.d-inline-block.align-top.unirac-logo{
    width: 159px;
    height: 34px;
}

.sso-navbar{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
}
.user-profile{
    display: flex;
    align-items: center;
    color: #0a3c5d;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
}

.nav-link.avtar{
    margin-left: 10px;
}

.nav-link.dropdown{
    position: relative;
    right: 10px;
}
.dashboard {
    margin-top: 10px;
    font-family: 'PT Sans';
}
.card-image{
    margin: 10px !important;
    cursor: pointer;
    height: 80px;
    /* justify-content: center;
    align-items: center;
    display: flex; */

}
#aTag{
    text-decoration: none;
    color: black;
}

.permitting.portal-image{
    width: 304px;
    height: 60px;
}

.cip-image{
    width: 254px;
    height: 122px;
}

.ubuilder2-image{
    width: 237px;
    height: 60px;
}

.domestic-solution-link{
    float: right;
    cursor: pointer;
}

.dashboard-heading{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 1.1px;
    color: #0083C2;
    margin-top: 70px;
    margin-bottom: 30px;
}

.applications{
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin: 0 20%; */
}

.application{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: -13px 11px 6px #00000029;
    border: 0.20000000298023224px solid #dfdcdc;
    border-radius: 5px;
    margin-left: 40px;
    height: 453px;
    width: 425px;
}

.application-description{
    background: #0A3C5D 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    color: #FFFFFF;
    padding: 20px 19px 29px 19px;
    position: relative;
    /* top: 10%; */
    height: 109px;
    font-size: 16px;
}

.application.unirac_ubuilder2>.application-description{
    padding: 20px 5px 29px 9px;
}

.application.permitting_portal>.application-description{
    padding: 20px 19px 29px 17px;
}

.application.unirac_cip>.application-description{
    padding: 20px 19px 29px 19px;
}

.application-image{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 344px;
}

.video-icon{
    float: right;
    margin-right: 5px;
    margin-top: 10px;
    cursor: pointer;
}

.cross-icon{
    float: right;
    margin-right: 5px;
    margin-top: 4px;
    position: relative;
    left: 100%;
    cursor: pointer;
}


.application-tutorial-video{
    background: transparent linear-gradient(128deg, #0083C2 0%, #004261 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px 5px 0 0;
    height: 344px;
    color: #FFFFFF;
    margin-bottom: 2px;
}

.tutorial-heading{
    font-size: 14px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 20px;
}
.tutorial-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.tutorial-video{
    width: 376px;
    height: 252px;
    margin: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    background: #FFFFFF;
    padding: 20px 5px;
    border-radius: 5px;
}

.application-name{
    font-weight: 700;
    font-size: 14px;
    padding-top:10px;
    color: #FFFFFF;
    text-transform: uppercase;
}
.loginBox{
    /* display: flex;
    flex-direction: column; */
    width: 55%;
    height: 80%;
    padding: 64px 64px 26px 64px;
    position: relative;
    top: 3rem;
    right: 8rem;
    background: rgba(255, 255, 255, 0.81) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    font-family: 'PT Sans';
    overflow-x: hidden;
    border-radius: 10px;
}
label{
    font-weight: 700;
}

.height80Vh{
    height: 80vh;
}
.text-center.login-title{
    font-family: 'Oswald';
}
.text-center.loginRegNavLink{
    color: #433F3E;
    margin-bottom: 88px;
}

.register-link{
    color: #2284C3;
    font-weight: 700;
}

input{
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5px solid #707070;
    border-radius: 5px;
    opacity: 1;
}

input[type="checkbox"]{
    background-color: #2085C3;
    width: 17px;
    height: 17px;
}

.form-group.email, .form-group.password, .form-group.confirm-password {
    margin-bottom: 44px;
    height: 38px;
}


.form-group.forgot-password-part{
    display: flex;
    justify-content: space-between;
    margin-bottom: 44px;
}

.ubuilder-blue-logo{
    height: 30px;
    width: 180px;
    margin-bottom: 44px;
}

.unirac-light-blue-logo{
    position: relative;
    left: 75%;
    top: 5rem;
    height: 107px;
    width: 360px;
}

.forward-icon{
    float: right;
    height: 20px
}

.text-center.social-media-links>a{
    position: relative;
    margin-right: 10px;
}
body{
    background: #F4F8FC !important;
}
.verify-email{
    padding: 30px;
    color: #707070;
    font-family: 'PT Sans';
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 7px;
    position: relative;
    top:-80px;
    width: 75%;
    background: #FFFFFF;
}

.send-icon{
    height:100px;
    width:100px;
}

.flower-icon{
    position:absolute;
    top: 53%;
    left:80%;
    height: 453px;
    width: 348px;
    opacity: 0.69;
}

.resendEmailButtonColor{
    background-color:#cf5232 !important; 
    color: #fff !important; 
    padding: 3px 25px 3px 25px !important;
}

.bottom>button{
    padding-top: 2px;
    padding-bottom: 2px;
    margin-right: 50px;
}

.email-name{
    color: #2284c3;
}

.bottom>a{
    color: #2284c3;
    height: 29px;
    width: 130px;
}

.bottom>a>.right-arrow{
    height:15px;
    width:18px;
}

@media only screen and (max-height: 870px){
    .verify-email{
        top: 10%;
    }
}

@media only screen and (max-width: 470px){
    .verify-email{
        top: 10%;
        width: 95%;
    }
    .flower-icon{
        height: 200px;
        width: 200px;
        position:relative;
        left: 0%;
        top: 10%;
    }
}
.profile-verified{
    padding: 50px 20px 0 20px;
    color: #707070;
    font-family: 'PT Sans';
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 5px;
    position: relative;
    top:-160px;
    width: 50%;
    background: #FFFFFF;
}

.text-center>h3{
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 3%;
}

.profile-verified-icon{
    height:50px;
    width:50px;
    margin-bottom: 5%;
}

.timer{
    color: #2284c3;
    font-weight: bold;
}

.mobile-icon{
    position:absolute;
    top: 40%;
    left:58%;
    height: 447px;
    width: 745px;
    opacity: 0.69;
}

@media only screen and (max-width: 420px) {
    .profile-verified{
        top: 10%;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
}

@media only screen and (max-height: 870px) {
    .profile-verified{
        top: 10%;
        
    }
}
.RegisterBox{
    display: flex;
    flex-direction: column;
    width: 55%;
    height: 90%;
    padding: 64px 64px 26px 64px;
    position: relative;
    top: 8rem;
    right: 8rem;
    background: rgba(255, 255, 255, 0.81) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    font-family: 'PT Sans';
    overflow-x: hidden;
    border-radius: 10px;
}

hr{
    background-color: #707070 !important;
    width: 100%;
}
.loginRegNavLink{
    margin-top: 14px;
    margin-bottom: -10px;
    color: #433F3E;
}
.error-message{
    font-size: 12px;
    color: #f00;
}
.form-control.invalid{
    border: 1px solid #f00;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control.invalid:focus{
    border-color: rgb(230, 126, 126);
    box-shadow: 0 0 0 0.2rem rgb(248, 96, 96);
}

.text-center.registration-title{
    font-family: 'Oswald';
}

.login-link{
    color: #2284C3;
    font-weight: 700;
}

.unirac-white-logo{
    position: relative;
    left: 74%;
    height: 273px;
    width: 655px;


}
.view-password{
    position: relative;
    left: 92% ;
    bottom: 32px;
}
.view-password>img{
    height: 21px;
    width: 27px;
}
