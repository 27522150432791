.loginBox{
    /* display: flex;
    flex-direction: column; */
    width: 55%;
    height: 80%;
    padding: 64px 64px 26px 64px;
    position: relative;
    top: 3rem;
    right: 8rem;
    background: rgba(255, 255, 255, 0.81) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    font-family: 'PT Sans';
    overflow-x: hidden;
    border-radius: 10px;
}
label{
    font-weight: 700;
}

.height80Vh{
    height: 80vh;
}
.text-center.login-title{
    font-family: 'Oswald';
}
.text-center.loginRegNavLink{
    color: #433F3E;
    margin-bottom: 88px;
}

.register-link{
    color: #2284C3;
    font-weight: 700;
}

input{
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5px solid #707070;
    border-radius: 5px;
    opacity: 1;
}

input[type="checkbox"]{
    background-color: #2085C3;
    width: 17px;
    height: 17px;
}

.form-group.email, .form-group.password, .form-group.confirm-password {
    margin-bottom: 44px;
    height: 38px;
}


.form-group.forgot-password-part{
    display: flex;
    justify-content: space-between;
    margin-bottom: 44px;
}

.ubuilder-blue-logo{
    height: 30px;
    width: 180px;
    margin-bottom: 44px;
}

.unirac-light-blue-logo{
    position: relative;
    left: 75%;
    top: 5rem;
    height: 107px;
    width: 360px;
}

.forward-icon{
    float: right;
    height: 20px
}

.text-center.social-media-links>a{
    position: relative;
    margin-right: 10px;
}