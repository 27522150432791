.profile-verified{
    padding: 50px 20px 0 20px;
    color: #707070;
    font-family: 'PT Sans';
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 5px;
    position: relative;
    top:-160px;
    width: 50%;
    background: #FFFFFF;
}

.text-center>h3{
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 3%;
}

.profile-verified-icon{
    height:50px;
    width:50px;
    margin-bottom: 5%;
}

.timer{
    color: #2284c3;
    font-weight: bold;
}

.mobile-icon{
    position:absolute;
    top: 40%;
    left:58%;
    height: 447px;
    width: 745px;
    opacity: 0.69;
}

@media only screen and (max-width: 420px) {
    .profile-verified{
        top: 10%;
        width: fit-content;
    }
}

@media only screen and (max-height: 870px) {
    .profile-verified{
        top: 10%;
        
    }
}