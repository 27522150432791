.dashboard {
    margin-top: 10px;
    font-family: 'PT Sans';
}
.card-image{
    margin: 10px !important;
    cursor: pointer;
    height: 80px;
    /* justify-content: center;
    align-items: center;
    display: flex; */

}
#aTag{
    text-decoration: none;
    color: black;
}

.permitting.portal-image{
    width: 304px;
    height: 60px;
}

.cip-image{
    width: 254px;
    height: 122px;
}

.ubuilder2-image{
    width: 237px;
    height: 60px;
}

.domestic-solution-link{
    float: right;
    cursor: pointer;
}

.dashboard-heading{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 1.1px;
    color: #0083C2;
    margin-top: 70px;
    margin-bottom: 30px;
}

.applications{
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin: 0 20%; */
}

.application{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: -13px 11px 6px #00000029;
    border: 0.20000000298023224px solid #dfdcdc;
    border-radius: 5px;
    margin-left: 40px;
    height: 453px;
    width: 425px;
}

.application-description{
    background: #0A3C5D 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    color: #FFFFFF;
    padding: 20px 19px 29px 19px;
    position: relative;
    /* top: 10%; */
    height: 109px;
    font-size: 16px;
}

.application.unirac_ubuilder2>.application-description{
    padding: 20px 5px 29px 9px;
}

.application.permitting_portal>.application-description{
    padding: 20px 19px 29px 17px;
}

.application.unirac_cip>.application-description{
    padding: 20px 19px 29px 19px;
}

.application-image{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 344px;
}

.video-icon{
    float: right;
    margin-right: 5px;
    margin-top: 10px;
    cursor: pointer;
}

.cross-icon{
    float: right;
    margin-right: 5px;
    margin-top: 4px;
    position: relative;
    left: 100%;
    cursor: pointer;
}


.application-tutorial-video{
    background: transparent linear-gradient(128deg, #0083C2 0%, #004261 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px 5px 0 0;
    height: 344px;
    color: #FFFFFF;
    margin-bottom: 2px;
}

.tutorial-heading{
    font-size: 14px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 20px;
}
.tutorial-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.tutorial-video{
    width: 376px;
    height: 252px;
    margin: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    background: #FFFFFF;
    padding: 20px 5px;
    border-radius: 5px;
}

.application-name{
    font-weight: 700;
    font-size: 14px;
    padding-top:10px;
    color: #FFFFFF;
    text-transform: uppercase;
}