@font-face {
  font-family: 'PT Sans';
  src: url('../src/assets/fonts/PTSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('../src/assets/fonts/Oswald-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.btn.btn-block.submitButtonColor{
  color: #fff !important; 
  background: #2284C3 0% 0% no-repeat padding-box !important;
  box-shadow: 7px 6px 4px #00000029;
  border-radius: 5px;
  opacity: 1;
  font-weight: 700;
  padding: 10px;
  margin-bottom: 44px;
}

.width100 {
  width: 100%;
}

.App.login{
  background: url('../src/static/login_background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 70%;
  height: calc(100vh);
  margin: 0;
  position: relative;
}

.App.registration{
  background: url('../src/static/register_background.png');
  background-repeat: no-repeat;
  background-position: 44% 77%;
  background-origin: padding-box;
  margin: 0;
  height: calc(100vh);
  position: relative;
}

.App.dashboard{
  background: transparent url('../src/static/dashboard_background.png');
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: 110%;
  background-origin: padding-box;
  opacity: 0.95;
  height: calc(99vh);
  margin: 0;
  position: relative;
}
