.RegisterBox{
    display: flex;
    flex-direction: column;
    width: 55%;
    height: 90%;
    padding: 64px 64px 26px 64px;
    position: relative;
    top: 8rem;
    right: 8rem;
    background: rgba(255, 255, 255, 0.81) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    font-family: 'PT Sans';
    overflow-x: hidden;
    border-radius: 10px;
}

hr{
    background-color: #707070 !important;
    width: 100%;
}
.loginRegNavLink{
    margin-top: 14px;
    margin-bottom: -10px;
    color: #433F3E;
}
.error-message{
    font-size: 12px;
    color: #f00;
}
.form-control.invalid{
    border: 1px solid #f00;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control.invalid:focus{
    border-color: rgb(230, 126, 126);
    box-shadow: 0 0 0 0.2rem rgb(248, 96, 96);
}

.text-center.registration-title{
    font-family: 'Oswald';
}

.login-link{
    color: #2284C3;
    font-weight: 700;
}

.unirac-white-logo{
    position: relative;
    left: 74%;
    height: 273px;
    width: 655px;


}
.view-password{
    position: relative;
    left: 92% ;
    bottom: 32px;
}
.view-password>img{
    height: 21px;
    width: 27px;
}