@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
body{
    background: #F4F8FC !important;
}
.verify-email{
    padding: 30px;
    color: #707070;
    font-family: 'PT Sans';
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 7px;
    position: relative;
    top:-80px;
    width: 75%;
    background: #FFFFFF;
}

.send-icon{
    height:100px;
    width:100px;
}

.flower-icon{
    position:absolute;
    top: 53%;
    left:80%;
    height: 453px;
    width: 348px;
    opacity: 0.69;
}

.resendEmailButtonColor{
    background-color:#cf5232 !important; 
    color: #fff !important; 
    padding: 3px 25px 3px 25px !important;
}

.bottom>button{
    padding-top: 2px;
    padding-bottom: 2px;
    margin-right: 50px;
}

.email-name{
    color: #2284c3;
}

.bottom>a{
    color: #2284c3;
    height: 29px;
    width: 130px;
}

.bottom>a>.right-arrow{
    height:15px;
    width:18px;
}

@media only screen and (max-height: 870px){
    .verify-email{
        top: 10%;
    }
}

@media only screen and (max-width: 470px){
    .verify-email{
        top: 10%;
        width: 95%;
    }
    .flower-icon{
        height: 200px;
        width: 200px;
        position:relative;
        left: 0%;
        top: 10%;
    }
}